import { Button, Link, Paper } from '@material-ui/core';
import { FC } from 'react';
import { Apple as AppleIcon } from '../../icons/apple';

export const RESOLVED_PATH = 'token';

export const LoginApple: FC = () => {
  const redirectUrl = `${window.location.origin}/${RESOLVED_PATH}`;

  const href = `${
    process.env.REACT_APP_APPLE_AUTH_URL || process.env.REACT_APP_BACKEND_URL
  }/auth/apple?redirectTo=${redirectUrl}`;

  return (
    <Paper elevation={1}>
      <Link
        component={Button}
        href={href}
        fullWidth
        size="large"
        startIcon={<AppleIcon />}
        sx={{
          background: '#000',
          color: '#fff',

          '&:hover': {
            background: '#000',
            opacity: 0.9,
          },
        }}
      >
        Continue with Apple
      </Link>
    </Paper>
  );
};
