import { FC, useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useAuth } from '../hooks/use-auth';

export const TenantGuard: FC = () => {
  const { tenantId } = useParams();
  const { tenant, setTenantById } = useAuth();

  useEffect(() => {
    if (tenant?.id !== tenantId) {
      setTenantById(tenantId);
    }
  }, [tenant, tenantId]);

  return <Outlet />;
};
