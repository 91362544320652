import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useCallback } from 'react';
import toast from 'react-hot-toast';
import logger from 'src/utils/logger';
// eslint-disable-next-line import/no-cycle
import { useAuth } from './use-auth';

// Authorization
const configAuthRequest = async (config: AxiosRequestConfig) => {
  logger('[axios/request]', config);
  return config;
};
// Response
const configAuthResponse = (response: AxiosResponse) => {
  logger('[axios/response]', response);
  return response;
};

// Backend
const backendOptions: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_BACKEND_URL,
};

export const useAxios = (idToken?: string) => {
  const { token, logout } = useAuth();

  const authRequest = useCallback(
    (config: AxiosRequestConfig) => {
      if (token) {
        return {
          ...config,
          headers: {
            authorization: `Bearer ${idToken ?? token}`,
            ...config.headers,
          },
        };
      }

      return config;
    },
    [token],
  );

  const authResponseError = useCallback(
    (error: AxiosError) => {
      logger('[axios/response/error]', error.response);
      if (error?.response?.status === 401) {
        logout();
        toast.error('Authorization failed');
      }

      return Promise.reject(error);
    },
    [logout],
  );

  const axInstance = axios.create(backendOptions);
  axInstance.interceptors.request.use(configAuthRequest);
  axInstance.interceptors.request.use(authRequest);
  axInstance.interceptors.response.use(configAuthResponse, authResponseError);

  return axInstance;
};
