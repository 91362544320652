import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import { AuthGuard } from './components/auth-guard';
import { GuestGuard } from './components/guest-guard';
import { LoadingScreen } from './components/loading-screen';
import { PermissionsGuard } from './components/permissions-guard';
import { TenantGuard } from './components/tenant-guard';
import { DashboardLayout } from './containers/dashboard-layout';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Not found pages
const NotFound = Loadable(
  lazy(() =>
    import('./containers/not-found').then((module) => ({
      default: module.NotFound,
    })),
  ),
);

// Auth pages
const Login = Loadable(
  lazy(() =>
    import('./containers/login').then((module) => ({
      default: module.Login,
    })),
  ),
);

const Token = Loadable(
  lazy(() =>
    import('./containers/token').then((module) => ({
      default: module.Token,
    })),
  ),
);

const ErrorPage = Loadable(
  lazy(() =>
    import('./containers/error-page').then((module) => ({
      default: module.ErrorPage,
    })),
  ),
);

const NoPermissions = Loadable(
  lazy(() =>
    import('./containers/no-permissions').then((module) => ({
      default: module.NoPermissions,
    })),
  ),
);

// Dashboard pages
const Items = Loadable(
  lazy(() =>
    import('./containers/items').then((module) => ({
      default: module.Items,
    })),
  ),
);

const ItemCreate = Loadable(
  lazy(() =>
    import('./containers/item-create').then((module) => ({
      default: module.ItemCreate,
    })),
  ),
);

const Item = Loadable(
  lazy(() =>
    import('./containers/item').then((module) => ({
      default: module.Item,
    })),
  ),
);

const ItemDetails = Loadable(
  lazy(() =>
    import('./containers/item-details').then((module) => ({
      default: module.ItemDetails,
    })),
  ),
);

const ItemMedia = Loadable(
  lazy(() =>
    import('./containers/item-media').then((module) => ({
      default: module.ItemMedia,
    })),
  ),
);

const ItemBlockchain = Loadable(
  lazy(() =>
    import('./containers/item-blockchain').then((module) => ({
      default: module.ItemBlockchain,
    })),
  ),
);

const SKUs = Loadable(
  lazy(() =>
    import('./containers/skus').then((module) => ({
      default: module.SKUs,
    })),
  ),
);

const SKUCreate = Loadable(
  lazy(() =>
    import('./containers/sku-create').then((module) => ({
      default: module.SKUCreate,
    })),
  ),
);

const SKU = Loadable(
  lazy(() =>
    import('./containers/sku').then((module) => ({
      default: module.SKU,
    })),
  ),
);

const StatisticsTenant = Loadable(
  lazy(() =>
    import('./containers/statistics-tenant').then((module) => ({
      default: module.StatisticsTenant,
    })),
  ),
);

const StatisticsGlobal = Loadable(
  lazy(() =>
    import('./containers/statistics-global').then((module) => ({
      default: module.StatisticsGlobal,
    })),
  ),
);

const ItemTimeline = Loadable(
  lazy(() =>
    import('./containers/item-timeline').then((module) => ({
      default: module.ItemTimeline,
    })),
  ),
);

const Tenants = Loadable(
  lazy(() =>
    import('./containers/tenants').then((module) => ({
      default: module.Tenants,
    })),
  ),
);

const Users = Loadable(
  lazy(() =>
    import('./containers/users').then((module) => ({
      default: module.Users,
    })),
  ),
);

const UserCreate = Loadable(
  lazy(() =>
    import('./containers/user-create').then((module) => ({
      default: module.UserCreate,
    })),
  ),
);

const UserAdd = Loadable(
  lazy(() =>
    import('./containers/user-add').then((module) => ({
      default: module.UserAdd,
    })),
  ),
);

const UserEdit = Loadable(
  lazy(() =>
    import('./containers/user-edit').then((module) => ({
      default: module.UserEdit,
    })),
  ),
);

const UsersAll = Loadable(
  lazy(() =>
    import('./containers/users-all').then((module) => ({
      default: module.UsersAll,
    })),
  ),
);

const UserAdminCreate = Loadable(
  lazy(() =>
    import('./containers/user-admin-create').then((module) => ({
      default: module.UserAdminCreate,
    })),
  ),
);

const routes: PartialRouteObject[] = [
  // {
  //   path: '/',
  //   element: <Navigate to="/" replace />,
  // },
  {
    path: 'token',
    element: (
      <GuestGuard>
        <Token />
      </GuestGuard>
    ),
  },
  {
    path: '500',
    element: (
      <GuestGuard>
        <ErrorPage />
      </GuestGuard>
    ),
  },
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  // {
  //   path: 'register',
  //   element: (
  //     <GuestGuard>
  //       <Register />
  //     </GuestGuard>
  //   ),
  // },
  // {
  //   path: 'verify-code',
  //   element: (
  //     <GuestGuard>
  //       <VerifyCode />
  //     </GuestGuard>
  //   ),
  // },
  // {
  //   path: 'password-recovery',
  //   element: (
  //     <GuestGuard>
  //       <PasswordRecovery />
  //     </GuestGuard>
  //   ),
  // },
  // {
  //   path: 'password-reset',
  //   element: <PasswordReset />,
  // },
  {
    path: 'no-permissions',
    element: (
      <AuthGuard>
        <NoPermissions />
      </AuthGuard>
    ),
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: 'users',
        children: [
          {
            path: '/',
            element: (
              <PermissionsGuard permission="ADMIN">
                <UsersAll />
              </PermissionsGuard>
            ),
          },
          {
            path: 'create',
            element: (
              <PermissionsGuard permission="ADMIN">
                <UserAdminCreate />
              </PermissionsGuard>
            ),
          },
        ],
      },
      {
        path: 'tenants',
        children: [
          {
            path: '/',
            element: (
              <PermissionsGuard permission="ADMIN">
                <Tenants />
              </PermissionsGuard>
            ),
          },
          {
            path: ':tenantId',
            element: <TenantGuard />,
            children: [
              {
                path: 'users',
                children: [
                  {
                    path: '/',
                    element: (
                      <PermissionsGuard permission="TENANT:USERS">
                        <Users />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: 'create',
                    element: (
                      <PermissionsGuard permission="TENANT:USERS">
                        <UserCreate />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: 'admin',
                    children: [
                      {
                        path: '/',
                        element: (
                          <PermissionsGuard permission="ADMIN">
                            <UsersAll />
                          </PermissionsGuard>
                        ),
                      },
                      {
                        path: 'create',
                        element: (
                          <PermissionsGuard permission="ADMIN">
                            <UserAdminCreate />
                          </PermissionsGuard>
                        ),
                      },
                    ],
                  },
                  {
                    path: ':userId',
                    children: [
                      {
                        path: 'add',
                        element: (
                          <PermissionsGuard permission="TENANT:USERS">
                            <UserAdd />
                          </PermissionsGuard>
                        ),
                      },
                      {
                        path: 'edit',
                        element: (
                          <PermissionsGuard permission="TENANT:USERS">
                            <UserEdit />
                          </PermissionsGuard>
                        ),
                      },
                    ],
                  },
                  //     {
                  //       path: ':userId',
                  //       children: [
                  //         {
                  //           path: '/',
                  //           element: <UserSummary />,
                  //         },
                  //       ],
                  //     },
                ],
              },
              {
                path: 'items',
                children: [
                  {
                    path: '/',
                    element: (
                      <PermissionsGuard permission="TENANT:ITEMS:LIST">
                        <Items />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: 'create',
                    element: (
                      <PermissionsGuard permission="TENANT:ITEMS:WRITE">
                        <ItemCreate />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: ':itemId',
                    element: (
                      <PermissionsGuard permission="TENANT:ITEMS:READ">
                        <Item />
                      </PermissionsGuard>
                    ),
                    children: [
                      {
                        path: '/',
                        element: <ItemDetails />,
                      },
                      {
                        path: 'media',
                        element: <ItemMedia />,
                      },
                      {
                        path: 'blockchain',
                        element: <ItemBlockchain />,
                      },
                      {
                        path: 'timeline',
                        element: <ItemTimeline />,
                      },
                    ],
                  },
                ],
              },
              {
                path: 'skus',
                children: [
                  {
                    path: '/',
                    element: (
                      <PermissionsGuard permission="TENANT:ITEMS:LIST">
                        <SKUs />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: 'create',
                    element: (
                      <PermissionsGuard permission="TENANT:ITEMS:WRITE">
                        <SKUCreate />
                      </PermissionsGuard>
                    ),
                  },
                  {
                    path: ':skuId',
                    element: (
                      <PermissionsGuard permission="TENANT:ITEMS:READ">
                        <SKU />
                      </PermissionsGuard>
                    ),
                  },
                ],
              },
              {
                path: 'statistics',
                element: (
                  <PermissionsGuard permission="TENANT:STATS">
                    <StatisticsTenant />
                  </PermissionsGuard>
                ),
              },
              // {
              //   path: 'customers',
              //   children: [
              //     {
              //       path: '/',
              //       element: <Customers />,
              //     },
              //     {
              //       path: ':customerId',
              //       element: <CustomerSummary />,
              //     },
              //   ],
              // },
            ],
          },
        ],
      },
      {
        path: 'statistics',
        element: (
          <PermissionsGuard permission="ADMIN">
            <StatisticsGlobal />
          </PermissionsGuard>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
