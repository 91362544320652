import { useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AppBar, Box, Button, Divider, IconButton, Toolbar } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { useSettings } from '../contexts/settings-context';
import { Moon as MoonIcon } from '../icons/moon';
import { Sun as SunIcon } from '../icons/sun';
import { AccountPopover } from './account-popover';
import { TenantPopover } from './tenants-popover';
import { Logo } from './logo';
import { DashboardNavbarMenu } from './dashboard-navbar-menu';
import { useAuth } from '../hooks/use-auth';
import { SUPER_ADMIN_TENANT } from '../types/tenant';

export const DashboardNavbar: FC = () => {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { settings, saveSettings } = useSettings();
  const { user: currentUser, tenant: currentTenant, tenants, setTenant } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [openMenu, setOpenMenu] = useState(false);
  const [darkMode, setDarkMode] = useState(settings.theme === 'dark');

  const handleSwitchTheme = (): void => {
    saveSettings({
      ...settings,
      theme: settings.theme === 'light' ? 'dark' : 'light',
    });

    setDarkMode(settings.theme === 'light');
  };

  const handleTenantChange = (tenantId: string): void => {
    const newTenant = tenants.find((ten) => ten.id === tenantId);

    if (newTenant?.id !== currentTenant.id) {
      toast.success('Tenant changed');
      
      // From/To Super Admin
      if (newTenant.id === SUPER_ADMIN_TENANT.id || currentTenant.id === SUPER_ADMIN_TENANT.id) {
        setTenant(newTenant);
        navigate('/');
      }
      // Tenants Path
      else if (location.pathname.startsWith('/tenants/')) {
        // Tenants path in format: /tenants/:tenantId/...
        const [tenantPath] = location.pathname.split('/').slice(3);
        navigate(`/tenants/${newTenant.id}/${tenantPath}`);
      }
      // Other Paths
      else {
        setTenant(newTenant);
      }
    }
  };

  return (
    <AppBar elevation={0} sx={{ backgroundColor: '#1e212a' }}>
      <Toolbar
        disableGutters
        sx={{
          alignItems: 'center',
          display: 'flex',
          minHeight: 64,
          px: 3,
          py: 1,
        }}
      >
        <Box
          component={RouterLink}
          to="/"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Logo variant="light" />
        </Box>
        <Divider
          flexItem
          orientation="vertical"
          sx={{
            borderColor: 'rgba(255,255,255,0.1)',
            mx: 3,
          }}
        />
        {currentTenant && (
          <TenantPopover
            currentTenant={currentTenant}
            onTenantChange={handleTenantChange}
            tenants={tenants}
          />
        )}
        <DashboardNavbarMenu onClose={() => setOpenMenu(false)} open={mdDown && openMenu} />
        <Button
          endIcon={
            <ChevronDownIcon
              fontSize="small"
              sx={{
                ml: 2,
                transition: 'transform 250ms',
                transform: openMenu ? 'rotate(180deg)' : 'none',
              }}
            />
          }
          onClick={() => setOpenMenu(true)}
          sx={{
            color: 'primary.contrastText',
            display: {
              md: 'none',
              xs: 'flex',
            },
          }}
          variant="text"
        >
          Menu
        </Button>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton
          color="inherit"
          onClick={handleSwitchTheme}
          sx={{
            mx: 2,
            display: {
              md: 'inline-flex',
              xs: 'none',
            },
          }}
        >
          {darkMode ? <SunIcon /> : <MoonIcon />}
        </IconButton>
        <AccountPopover
          currentUser={currentUser}
          darkMode={darkMode}
          onSwitchTheme={handleSwitchTheme}
        />
      </Toolbar>
    </AppBar>
  );
};
