export const ADMIN_KEY = 'ADMIN';

export const SUPER_ADMIN_TENANT: Tenant = {
  id: ADMIN_KEY,
  name: 'Super Admin',
};

export interface Tenant {
  id: string;
  name: string;
  walletPublicKey?: string | null;
  walletAddress?: string | null;
  contractAddress?: string | null;
  walletMnemonic?: string;
  walletDerivationIndex?: number;
  createdAt?: Date;
}

export interface UserTenants {
  userId: string;
  tenants: Tenant[];
}

export interface UserTenant {
  userId: string;
  tenant: Tenant;
}
