import { useState } from 'react';
import type { FC, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuth } from '../hooks/use-auth';
import { Login } from '../containers/login';
import { SUPER_ADMIN_TENANT } from '../types/tenant';

interface AuthGuardProps {
  children: ReactNode;
}

export const AuthGuard: FC<AuthGuardProps> = (props) => {
  const { children } = props;
  const { isAuthenticated, tenant, tenants, user } = useAuth();
  const location = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  if (!isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    return <Login />;
  }

  if (isAuthenticated) {
    // User without permissions
    if (location.pathname !== '/no-permissions' && (!tenants || !tenants.length)) {
      return (
        <Navigate
          to={{
            pathname: '/no-permissions',
            search: `?email=${user?.email}`,
          }}
          replace
        />
      );
    }

    // Redirect from root to correct path
    if (location.pathname === '/') {
      const requestPath =
        tenant?.id === SUPER_ADMIN_TENANT.id ? '/users' : `/tenants/${tenant?.id}/users`;

      return <Navigate to={requestPath} replace />;
    }
  }

  // This is done so that in case the route changes by any chance through other
  // means between the moment of request and the render we navigate to the initially
  // requested route.
  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};
