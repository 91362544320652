import jwt from 'jsonwebtoken';

/** Id token key */
const ID_TOKEN_KEY = 'idToken';

/** Refresh token key */
const REFRESH_TOKEN_KEY = 'refreshToken';

/** Tenant key */
const TENANT_KEY = 'tenant';

/** Tenants key */
const TENANTS_KEY = 'tenants';

/** Local Storage Item */
class StorageItem {
  key: string;

  isJwt: boolean;

  constructor(key: string, isJwt: boolean = false) {
    this.key = key;
    this.isJwt = isJwt;
  }

  get() {
    return localStorage.getItem(this.key);
  }

  set(item: string) {
    localStorage.setItem(this.key, item);
  }

  remove() {
    localStorage.removeItem(this.key);
  }

  expired(addTime: number = 0) {
    if (!this.isJwt) {
      throw new Error('Not a JWT Token');
    }

    const token = localStorage.getItem(this.key);
    if (!token) {
      throw new Error('Token is missing');
    }

    const decodedToken = jwt.decode(token, { complete: true });
    const now = new Date();
    const time = (now.getTime() + addTime) / 1000;
    return time > decodedToken?.payload?.exp;
  }
}

export const storage = {
  idToken: new StorageItem(ID_TOKEN_KEY, true),
  refreshToken: new StorageItem(REFRESH_TOKEN_KEY, true),
  tenant: new StorageItem(TENANT_KEY),
  tenants: new StorageItem(TENANTS_KEY),
  clear: () => {
    [ID_TOKEN_KEY, REFRESH_TOKEN_KEY, TENANT_KEY, TENANTS_KEY].forEach((key) => {
      localStorage.removeItem(key);
    });
  },
};
