import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import jwt from 'jsonwebtoken';
import { AxiosRequestConfig } from 'axios';
import toast from 'react-hot-toast';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import type { User } from '../types/user';
// eslint-disable-next-line import/no-cycle
import { authApi } from '../api/auth';
// eslint-disable-next-line import/no-cycle
import { useAxios } from '../hooks/use-axios';
import { storage } from '../utils/storage';
import { Tenant, UserTenant, UserTenants } from '../types/tenant';
import logger from '../utils/logger';

interface MeRequest {
  idToken: string;
  refetch?: boolean;
}

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: User | null;
  tenant?: Tenant;
  tenants: Tenant[];
  error?: string;
}

interface AuthContextValue extends State {
  method: 'JWT';
  token?: string;
  authHeader?: () => AxiosRequestConfig;
  login: (email: string, password: string) => Promise<void>;
  refresh: () => Promise<void>;
  logout: () => Promise<void>;
  refreshTenants: () => void;
  setTenant: (tenant: Tenant) => void;
  setTenantById: (tenantId: string) => void;
  setTokens: (idToken: string, refreshToken: string) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
    tenant?: Tenant;
    tenants?: Tenant[];
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    tenant: Tenant;
    tenants: Tenant[];
  };
};

type LoginErrorAction = {
  type: 'LOGIN_ERROR';
  payload: {
    error: string;
  };
};

type RefreshAction = {
  type: 'REFRESH';
  payload: {
    user: User;
    tenant: Tenant;
    tenants: Tenant[];
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RefreshTenantsAction = {
  type: 'REFRESH_TENANTS';
  payload: {
    tenants: Tenant[];
    tenant: Tenant;
  };
};
type SetTenantAction = {
  type: 'SET_TENANT';
  payload: {
    tenant: Tenant;
  };
};

type Action =
  | InitializeAction
  | LoginAction
  | LoginErrorAction
  | RefreshAction
  | LogoutAction
  | RefreshTenantsAction
  | SetTenantAction;

const TIME_BEFORE_EXPIRE = 5 * 60 * 1000;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  tenant: null,
  tenants: [],
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, user, tenant, tenants } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      tenant,
      tenants,
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user, tenant, tenants } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      tenant,
      tenants,
      error: initialState.error,
    };
  },
  LOGIN_ERROR: (state: State, action: LoginErrorAction): State => {
    const { error } = action.payload;

    return {
      ...state,
      isAuthenticated: false,
      error,
    };
  },
  REFRESH: (state: State, action: RefreshAction): State => {
    const { user, tenant, tenants } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      tenant,
      tenants,
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null,
    tenant: null,
    tenants: [],
    error: initialState.error,
  }),
  REFRESH_TENANTS: (state: State, action: RefreshTenantsAction): State => {
    const { tenants, tenant } = action.payload;

    return {
      ...state,
      tenants,
      tenant,
    };
  },
  SET_TENANT: (state: State, action: SetTenantAction): State => {
    const { tenant } = action.payload;

    return {
      ...state,
      tenant,
    };
  },
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  method: 'JWT',
  login: () => Promise.resolve(),
  refresh: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  refreshTenants: () => {},
  setTenant: () => {},
  setTenantById: () => {},
  setTokens: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const axios = useAxios();
  const navigate = useNavigate();

  const token = storage.idToken.get();

  const logout = async (): Promise<void> => {
    // Remove tokens and tenants
    storage.clear();

    dispatch({ type: 'LOGOUT' });
  };

  const refresh = async (): Promise<void> => {
    try {
      const refreshToken = storage.refreshToken.get();
      if (!refreshToken) {
        throw new Error('No refresh token');
      }

      // Get new tokens
      const { idToken: newIdToken, refreshToken: newRefreshToken } = await authApi.refresh(
        refreshToken,
        axios,
      );

      // Store new tokens
      storage.idToken.set(newIdToken);
      storage.refreshToken.set(newRefreshToken);

      // Get user
      const { tenant, tenants, ...user } = await authApi.me(newIdToken);

      dispatch({
        type: 'REFRESH',
        payload: {
          tenant,
          tenants,
          user,
        },
      });
      toast.success('Successful refresh');
    } catch (err) {
      toast.error('Unsuccessful refresh');
      logout();
    }
  };

  const setTenant = (tenant: Tenant) => {
    storage.tenant.set(JSON.stringify({ userId: state.user.id, tenant }));

    dispatch({
      type: 'SET_TENANT',
      payload: {
        tenant,
      },
    });
  };

  const setTenantById = (tenantId: string) => {
    if (state.tenant?.id !== tenantId) {
      const tenant = state.tenants.find((t) => t.id === tenantId);

      if (tenant) {
        setTenant(tenant);
      } else {
        toast.error('Tenant not found');
        navigate('/notFound/tenant');
      }
    }
  };

  const authHeader = (): AxiosRequestConfig => {
    const t = storage.idToken.get();
    return (
      t && {
        headers: {
          authorization: `Bearer ${t}`,
        },
      }
    );
  };

  const { error: refreshError } = useQuery(['refresh', token], async () => {
    const t = storage.idToken.get();
    if (token && token === t) {
      const tokenExpired = storage.idToken.expired(TIME_BEFORE_EXPIRE);
      if (tokenExpired) {
        if (!storage.refreshToken.expired()) {
          return refresh();
        }
        throw new Error('Refresh token expired');
      }
    }

    return undefined;
  });

  const meMutation = useMutation(
    ({ idToken, refetch }: MeRequest) => authApi.me(idToken, refetch),
    {
      onSuccess: (extuser) => {
        const { tenant, tenants, ...user } = extuser;
        // Store tenants
        storage.tenant.set(JSON.stringify({ userId: user.id, tenant }));
        storage.tenants.set(JSON.stringify({ userId: user.id, tenants }));
      },
      onError: () => {
        logout();
      },
    },
  );

  const loginMutation = useMutation(async (data: { email: string; password: string }) => {
    logger('[LOGIN/MUTATION/START]');
    return authApi.login(data);
  });

  const login = async (email: string, password: string): Promise<void> => {
    loginMutation.mutate(
      { email, password },
      {
        onSuccess: ({ idToken, refreshToken }) => {
          logger('[LOGIN/MUTATION/SUCCESS]', idToken);
          meMutation.mutate(
            { idToken },
            {
              onSuccess: ({ tenant, tenants, ...user }) => {
                logger('[ME/MUTATION/SUCCESS]', user);
                dispatch({
                  type: 'LOGIN',
                  payload: {
                    user,
                    tenant,
                    tenants,
                  },
                });
              },
              onError: (err: Error) => {
                logger('[ME/MUTATION/ERROR]', err);
                toast.error('Error while logging in');
                dispatch({
                  type: 'LOGIN_ERROR',
                  payload: {
                    error: err?.message,
                  },
                });
              },
            },
          );
          // Store tokens
          storage.idToken.set(idToken);
          storage.refreshToken.set(refreshToken);
        },
        onError: (err: Error) => {
          logger('[LOGIN/MUTATION/ERROR]', err?.message);
          dispatch({
            type: 'LOGIN_ERROR',
            payload: {
              error: err?.message,
            },
          });
        },
      },
    );
  };

  const refreshTenants = async (): Promise<void> => {
    meMutation.mutate(
      { idToken: storage.idToken.get(), refetch: true },
      {
        onSuccess: ({ tenant, tenants }) => {
          logger('[TENANTS/REFRESH]', tenants, tenant);
          dispatch({
            type: 'REFRESH_TENANTS',
            payload: {
              tenant,
              tenants,
            },
          });
        },
      },
    );
  };

  const setTokens = async (idToken: string, refreshToken: string) => {
    meMutation.mutate(
      { idToken },
      {
        onSuccess: ({ tenant, tenants, ...user }) => {
          logger('[SET_TOKENS/ME/MUTATION/SUCCESS]', user);

          // Store tokens
          storage.idToken.set(idToken);
          storage.refreshToken.set(refreshToken);

          dispatch({
            type: 'LOGIN',
            payload: {
              user,
              tenant,
              tenants,
            },
          });
        },
        onError: (err: Error) => {
          logger('[SET_TOKENS/ME/MUTATION/ERROR]', err);
          toast.error('Error while logging in');
          dispatch({
            type: 'LOGIN_ERROR',
            payload: {
              error: err?.message,
            },
          });
        },
      },
    );
  };

  useEffect(() => {
    if (refreshError) {
      logout();
      toast.error('Authentication expired. Please log in again');
    }
  }, [refreshError]);

  useEffect(() => {
    const initialize = () => {
      try {
        const idToken = storage.idToken.get();

        if (idToken) {
          const payload = jwt.decode(idToken);
          if (typeof payload !== 'string') {
            const user: User = {
              id: payload.id,
              name: payload.name,
              email: payload.email,
              permissions: payload.permissions,
            };

            const tenantsJson = storage.tenants.get();
            const userTenants: UserTenants = JSON.parse(tenantsJson);

            const tenantJson = storage.tenant.get();
            const userTenant: UserTenant = JSON.parse(tenantJson);

            if (userTenants && userTenant) {
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: true,
                  user,
                  tenant: userTenant.tenant,
                  tenants: userTenants.tenants,
                },
              });
            } else {
              throw new Error('Invalid tenants');
            }
          } else {
            throw new Error('Invalid token');
          }
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        token,
        authHeader,
        login,
        refresh,
        logout,
        setTenant,
        setTenantById,
        refreshTenants,
        setTokens,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
