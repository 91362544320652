import { Button, Link, Paper } from '@material-ui/core';
import { FC } from 'react';
import { Google as GoogleIcon } from '../../icons/google';

export const RESOLVED_PATH = 'token';

export const LoginGoogle: FC = () => {
  const redirectUrl = `${window.location.origin}/${RESOLVED_PATH}`;

  const href = `${
    process.env.REACT_APP_SSO_AUTH_URL || process.env.REACT_APP_BACKEND_URL
  }/auth/google?redirectTo=${redirectUrl}`;

  return (
    <Paper elevation={1}>
      <Link
        component={Button}
        href={href}
        fullWidth
        size="large"
        startIcon={<GoogleIcon />}
        sx={{
          background: '#fff',
          color: '#757575',
          textDecoration: 'none',
        }}
      >
        Continue with Google
      </Link>
    </Paper>
  );
};
