import type { FC } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
// import { useTranslation } from 'react-i18next';
import {
  // Avatar,
  Box,
  Typography,
  Popover,
  List,
  ListItem,
  // ListItemAvatar,
  ListItemText,
  ListItemIcon,
  // Switch,
  // ListSubheader
} from '@material-ui/core';
// import { InputField } from './input-field';
import { useAuth } from '../hooks/use-auth';
import { usePopover } from '../hooks/use-popover';
import { ChevronDown as ChevronDownIcon } from '../icons/chevron-down';
import { Logout as LogoutIcon } from '../icons/logout';
// import { OfficeBuilding as OfficeBuildingIcon } from '../icons/office-building';
// import { User as UserIcon } from '../icons/user';
import { lightNeutral } from '../colors';
// import { Tenant } from '../types/tenant';
import { User } from '../types/user';

interface AccountPopoverProps {
  currentUser: User;
  // currentTenant: Tenant;
  darkMode: boolean;
  // onLanguageChange: (newLanguage: 'en' | 'des' | 'es') => void;
  // onTenantChange: (tenantId: string) => void;
  // onSwitchDirection: () => void;
  onSwitchTheme: () => void;
  // tenants: Tenant[];
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const {
    currentUser,
    // currentTenant,
    darkMode,
    // onLanguageChange,
    // onTenantChange,
    // onSwitchDirection,
    onSwitchTheme,
    // tenants,
    ...other
  } = props;
  // const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  // const handleTenantChange = (event: ChangeEvent<HTMLInputElement>): void => {
  //   onTenantChange?.(event.target.value);
  // };

  // const handleLanguageChange = (event: ChangeEvent<HTMLInputElement>): void => {
  //   onLanguageChange(event.target.value as 'en' | 'des' | 'es');
  // };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong');
    }
  };

  return (
    <>
      <Box
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          cursor: 'pointer',
          display: 'flex',
          ml: 2
        }}
        {...other}
      >
        {/* <Avatar
          src="/static/user-chen_simmons.png"
          variant="rounded"
          sx={{
            height: 40,
            width: 40
          }}
        /> */}
        <Box
          sx={{
            alignItems: 'center',
            display: {
              md: 'flex',
              xs: 'none'
            },
            flex: 1,
            ml: 1,
            minWidth: 120
          }}
        >
          <div>
            <Typography
              sx={{
                color: lightNeutral[500]
              }}
              variant="caption"
            >
              {currentUser.email}
            </Typography>
            <Typography
              sx={{ color: 'primary.contrastText' }}
              variant="subtitle2"
            >
              {currentUser.name}
            </Typography>
          </div>
          <ChevronDownIcon
            sx={{
              color: 'primary.contrastText',
              ml: 1
            }}
          />
        </Box>
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            width: 260,
            display: 'flex',
            flexDirection: 'column'
          }
        }}
      >
        <List>
          <ListItem divider>
            <ListItemText
              primary={currentUser.name}
              secondary={currentUser.email}
            />
          </ListItem>
          {/* <ListItem
            button
            component={RouterLink}
            divider
            onClick={handleClose}
            to="/dashboard/account"
          >
            <ListItemIcon>
              <UserIcon />
            </ListItemIcon>
            <ListItemText primary="Account" />
          </ListItem> */}
          <ListItem
            button
            onClick={handleLogout}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

AccountPopover.propTypes = {
  // @ts-ignore
  currentUser: PropTypes.object.isRequired,
  // currentTenant: PropTypes.object.isRequired,
  darkMode: PropTypes.bool.isRequired,
  // onLanguageChange: PropTypes.func.isRequired,
  // onTenantChange: PropTypes.func.isRequired,
  // onSwitchDirection: PropTypes.func.isRequired,
  onSwitchTheme: PropTypes.func.isRequired,
  // tenants: PropTypes.array.isRequired,
  // rtlDirection: PropTypes.bool.isRequired
};
