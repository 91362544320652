import { createSvgIcon } from '@material-ui/core';

export const Google = createSvgIcon(
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20">
	<title>btn_google_light_normal_ios</title>
	<g id="Google-Button">
		<g id="btn_google_light_normal">
			<g id="logo_googleg_48dp">
				<path id="Shape" fillRule="evenodd" fill="#4285f4" d="m16 16.8h-3v-2.2c1-0.7 1.6-1.6 1.8-2.8h-4.8v-3.4h8.5q0.1 0.8 0.1 1.8c0 2.7-0.9 5.1-2.6 6.6z"/>
				<path id="Shape" fillRule="evenodd" fill="#34a853" d="m2 14v-2.3h3c0.7 2.1 2.7 3.7 5 3.7c1.2 0 2.2-0.3 3-0.8l3 2.2c-1.5 1.4-3.6 2.2-6 2.2c-3.5 0-6.6-2-8-5z"/>
				<path id="Shape" fillRule="evenodd" fill="#fbbc05" d="m2 14c-0.7-1.2-1-2.5-1-4c0-1.5 0.3-2.8 1-4h3v2.3q-0.3 0.8-0.3 1.7q0 0.9 0.3 1.7z"/>
				<path id="Shape" fillRule="evenodd" fill="#ea4335" d="m5 8.3l-3-2.3c1.4-3 4.5-5 8-5c2.4 0 4.5 0.9 6 2.3l-2.6 2.6c-0.9-0.9-2.1-1.3-3.4-1.3c-2.3 0-4.3 1.6-5 3.7z"/>
				<path id="Shape" fill="none" d="m1 1h18v18h-18z"/>
			</g>
		</g>
	</g>
</svg>,
  'Google',
);
