import { createSvgIcon } from '@material-ui/core/utils';

export const Apple = createSvgIcon(
  <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20">
    <g id="White-Logo-Square-">
      <path
        id=""
        fill="currentColor"
        d="m10 4.4c0-0.1-0.1-0.2-0.1-0.4c0-1 0.5-1.9 1-2.6c0.7-0.8 1.8-1.4 2.8-1.4q0 0.2 0 0.4c0 1-0.4 1.9-1 2.6c-0.6 0.8-1.6 1.4-2.5 1.4q-0.1 0-0.2 0zm-5.6 12.2c-1-1.6-1.9-4-1.9-6.3c0-3.7 2.3-5.7 4.6-5.7c1.2 0 2.3 0.9 3 0.9c0.7 0 1.9-0.9 3.3-0.9c0.5 0 2.4 0 3.6 1.9c-0.1 0.1-2 1.2-2 3.6c0 2.8 2.4 3.8 2.5 3.8c0 0.1-0.4 1.4-1.3 2.7c-0.8 1.2-1.6 2.4-2.8 2.4c-1.3 0-1.6-0.8-3-0.8c-1.5 0-2 0.8-3.1 0.8c-1.2 0-2-1.1-2.9-2.4z"
      />
    </g>
  </svg>,
  'Apple',
);
