import { useCallback, useMemo } from 'react';
import { Permission } from '../types/user';
import { useAuth } from './use-auth';

interface UsePermissionsResult {
  allowed: boolean;
  currentPermissions: Permission[];
  checkPermission: (permission?: Permission) => boolean;
}

export const usePermissions = (requiredPermission?: Permission): UsePermissionsResult => {
  const { user, tenant } = useAuth();

  const currentPermissions: Permission[] = useMemo(() => {
    // Admin Permissions
    if (user?.permissions?.ADMIN) {
      return ['ADMIN'];
    }

    // Permissions for current tenant
    const tenantPerms = user?.permissions?.[tenant?.id];
    return tenantPerms || [];
  }, [user, tenant]);

  const checkPermission = useCallback(
    (perm?: Permission) =>
      !perm || currentPermissions.includes('ADMIN') || currentPermissions.includes(perm),
    [currentPermissions],
  );

  const allowed = useMemo(
    () => checkPermission(requiredPermission),
    [requiredPermission, checkPermission],
  );

  return {
    allowed,
    currentPermissions,
    checkPermission,
  };
};
