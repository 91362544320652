import { AxiosInstance } from 'axios';
import { storage } from '../utils/storage';
import { SUPER_ADMIN_TENANT, Tenant, UserTenant, UserTenants } from '../types/tenant';
import { ExternalUser, User } from '../types/user';
import axios from '../utils/axios';
import { compareDistinctArray } from '../utils/utils';
import logger from '../utils/logger';

interface LoginRequest {
  email: string;
  password: string;
}

interface AuthTokens {
  idToken: string;
  refreshToken: string;
}

type LoginResponse = AuthTokens;

type RefreshResponse = AuthTokens;

type UserResponse = User;

interface TenantsResponse {
  tenants: Tenant[];
}

class AuthApi {
  async login(data: LoginRequest): Promise<AuthTokens> {
    let result = null;
    try {
      const response = await axios.post<LoginResponse>('/auth/login', data);
      result = response.data;
    } catch (err) {
      logger('[API/AUTH/LOGIN/ERROR]', err.response.data.E_MESSAGE);
      throw new Error(err.response?.data?.E_MESSAGE ?? err);
    }

    return result;
  }

  async me(idToken: string, refresh: boolean = false): Promise<ExternalUser> {
    const headerAuth = {
      headers: {
        authorization: `Bearer ${idToken}`,
      },
    };

    let user: ExternalUser = null;
    try {
      // Get user from server
      const userResponse = await axios.get<UserResponse>('/users/me', headerAuth);

      user = userResponse.data;

      if (!refresh) {
        // Get tenants from local storage
        const tenantsJson = storage.tenants.get();
        if (tenantsJson && typeof tenantsJson === 'string') {
          const { userId, tenants }: UserTenants = JSON.parse(tenantsJson);

          if (
            userId === user.id &&
            (user.permissions.ADMIN || compareDistinctArray(tenants, Object.keys(user.permissions)))
          ) {
            user.tenants = tenants;
          }
        }
      }

      // Get tenants from server
      if (refresh || !user.tenants) {
        const tenantsResponse = await axios.get<TenantsResponse>('/users/me/tenants', headerAuth);

        const { tenants } = tenantsResponse.data;

        user.tenants = tenants;
      }

      if (user.permissions.ADMIN && user.tenants.every((t) => t.id !== SUPER_ADMIN_TENANT.id)) {
        user.tenants = [SUPER_ADMIN_TENANT, ...user.tenants];
      }

      // Get selected tenant
      const tenantJson = storage.tenant.get();
      if (tenantJson && typeof tenantJson === 'string') {
        const { userId, tenant }: UserTenant = JSON.parse(tenantJson);

        logger('[ME/TENANT] Selected tenant', tenant, user);
        const correctTenant = user.tenants.find((t) => t.id === tenant.id);
        if (
          correctTenant &&
          userId === user.id &&
          (user.permissions.ADMIN || user.permissions[tenant.id])
        ) {
          logger('[ME/TENANT/SELECTED]', user, tenant);
          user.tenant = correctTenant;
        }
      }
      // Select default tenant
      if (!user.tenant) {
        logger('[ME/TENANT] Not selected tenant');
        [user.tenant] = user.tenants;
      }
    } catch (err) {
      logger('[API/AUTH/ME/ERROR]', err);
      throw new Error(err.response?.data?.E_MESSAGE ?? err.message);
    }

    return user;
  }

  async refresh(refreshToken: string, ax: AxiosInstance): Promise<AuthTokens> {
    const refreshResponse = await ax.post<RefreshResponse>('/auth/refresh', { refreshToken });

    return refreshResponse.data as AuthTokens;
  }
}

export const authApi = new AuthApi();
